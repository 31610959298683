import React, { useState } from 'react';
import './MobileBurgerMenu.css';

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className={`burger-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <nav className={`nav-menu ${isOpen ? 'active' : ''}`}>
        <ul>
          <li>
            <a href="#prispevky" onClick={toggleMenu}>
              Příspěvky
            </a>
          </li>
          <li>
            <a href="#fotky" onClick={toggleMenu}>
              Fotky
            </a>
          </li>
          <li>
            <a href="#akce" onClick={toggleMenu}>
              Akce
            </a>
          </li>
        </ul>
      </nav>
      {isOpen && <div className="overlay" onClick={toggleMenu}></div>}
    </div>
  );
};

export default BurgerMenu;
