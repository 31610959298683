import SimpleArticle from './SimpleArticle';
import './Articles.css';

const Articles = () => {
  return (
    <section id="prispevky" className="articles">
      <SimpleArticle />
    </section>
  );
};

export default Articles;
