import MobileBurgerMenu from './Components/MobileBurgerMenu';
import Articles from './Components/Articles';
import Calendar from './Components/Calendar';
import Gallery from './Components/Gallery';
import LoginPopup from './Components/LoginPopup';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="background"></div>
        <div className="site-logo">
          <img src="/logo.png" alt="Prague Corps Logo" className="logo" />
        </div>
        <LoginPopup />
        <MobileBurgerMenu />
        <div className="desktop-menu">
          <nav>
            <a href="#prispevky">Příspěvky</a>
            <a href="#fotky">Fotky</a>
            <a href="#akce">Akce</a>
          </nav>
        </div>
      </header>
      <Articles />
      <Gallery />
      <Calendar />
    </div>
  );
}

export default App;
