import React, { useState } from 'react';
import './Calendar.css';

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);

  const events = {
    '2024-11-15': 'Ukončení sezóny',
  };

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const renderDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const today = new Date();

    const days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const dateKey = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      const hasEvent = events[dateKey];
      const isToday = today.getFullYear() === year && today.getMonth() === month && today.getDate() === day;
      days.push(
        <div key={day} className={`calendar-day ${hasEvent ? 'event' : ''} ${isToday ? 'today' : ''}`} onClick={() => hasEvent && setSelectedEvent(events[dateKey])}>
          {day}
        </div>
      );
    }

    return days;
  };

  return (
    <div className="calendar-container" id="akce">
      <div className="calendar-header">
        <button onClick={handlePrevMonth}>&lt;</button>
        <h2>
          {currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}
        </h2>
        <button onClick={handleNextMonth}>&gt;</button>
      </div>
      <div className="calendar-grid">{renderDays()}</div>
      {selectedEvent && (
        <div className="event-details">
          <h3>Detaily akce</h3>
          <p>{selectedEvent}</p>
          <button onClick={() => setSelectedEvent(null)}>Zavřít</button>
        </div>
      )}
    </div>
  );
};

export default Calendar;
