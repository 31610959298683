import React, { useEffect, useState } from 'react';
import './PhotosInAlbum.css';
import './GallerySection.css';
import axios from 'axios';

const PhotosInAlbum = ({ album, password, setIsLoading, onBackToAlbums }) => {
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState(null);
  const [lightboxIndex, setLightboxIndex] = useState(null);

  useEffect(() => {
    const fetchPhotos = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`https://getphotosinalbum-qozsfwer2a-uc.a.run.app?album=${album}`, {
          headers: {
            'x-custom-password': password,
          },
        });
        setPhotos(response.data.photoLinks);
      } catch (err) {
        setError('Error fetching photos in album.');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (album) {
      fetchPhotos();
    }
  }, [album, password]); // Odebráno `setIsLoading` z pole závislostí

  const openLightbox = (index) => {
    setLightboxIndex(index);
  };

  const closeLightbox = () => {
    setLightboxIndex(null);
  };

  const showPrevPhoto = () => {
    setLightboxIndex((prevIndex) => (prevIndex === 0 ? photos.length - 1 : prevIndex - 1));
  };

  const showNextPhoto = () => {
    setLightboxIndex((prevIndex) => (prevIndex === photos.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div>
      <div className="album-header">
        <h3>Fotky v albu: {album}</h3>
        <button className="back-button" onClick={onBackToAlbums}>
          Zpět na seznam alb
        </button>
      </div>
      {error && <p>{error}</p>}
      <div className="photos-container">
        {photos.map((photo, index) => (
          <div className="photo-item" key={index} onClick={() => openLightbox(index)}>
            <img src={photo} alt={`Photo ${index + 1}`} />
          </div>
        ))}
      </div>

      {lightboxIndex !== null && (
        <div className="photo-lightbox">
          <div className="lightbox-overlay" onClick={closeLightbox}></div>
          <div className="photo-lightbox-content">
            <img src={photos[lightboxIndex]} alt={`Photo ${lightboxIndex + 1}`} />
            <div className="lightbox-controls">
              <span className="lightbox-arrow" onClick={showPrevPhoto}>
                &larr;
              </span>
              <span className="lightbox-arrow" onClick={showNextPhoto}>
                &rarr;
              </span>
            </div>
            <span className="lightbox-close" onClick={closeLightbox}>
              &times;
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotosInAlbum;
