import React, { useState } from 'react';
import './LoginPopup.css';

const LoginPopup = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLoginClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setErrorMessage('');
    setEmail('');
    setPassword('');
  };

  const handleSubmit = () => {
    // Simulace neúspěšného přihlášení
    setErrorMessage('Nesprávné údaje, kontaktuj administrátora.');
  };

  return (
    <div className="login-page">
      <button className="login-button" onClick={handleLoginClick}>
        Přihlásit
      </button>
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-inner">
            <h2>Přihlášení</h2>
            <input type="email" className="input-field" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="password" className="input-field" placeholder="Heslo" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button className="submit-button" onClick={handleSubmit}>
              Odeslat
            </button>
            {errorMessage && <p className="error">{errorMessage}</p>}
            <button className="close-button" onClick={handleClosePopup}>
              Zavřít
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPopup;
