const SimpleArticle = () => {
  return (
    <article>
      <div className="article-header">
        <div className="publiced-on">
          <p className="day">26</p>
          <p month>Ún 19</p>
        </div>
        <div className="article-details">
          <h3>Hadry kolekce 2019</h3>
          <p>
            <span>Kategorie</span>: Příspěvky | <span>Napsal</span>: Dodo
          </p>
        </div>
      </div>
      <div className="article-content">
        <div class="blog-content">
          <p>
            Čau,
            <br />
            dáváme s Dodem dohromady kolekci na rok 2019.
          </p>
          <p>Bude se jednat o:</p>
          <p>
            <strong>Mikina classic černá s kapucí S,M,L,XL,2XL,3XL</strong>
            <br />
            65% bavlna, logo přes celé torzo jako u našich předešlých mikin.
            <br />
            <a href="https://www.inetprint.cz/panska-mikina-adler-malfini-cape---rtx_pm2069?fbclid=IwAR3lTSvSjqV-nTi48rBpZAgo_YPa9ewl30uySM4KN_V5kW8KLkJ12LvyNBM">Odkaz zde</a>
          </p>
          <p>
            <strong>Mikina classic černá bez kapuci S,M,L,XL,2XL,3XL</strong>
            <br />
            65% bavlna, logo přes celé torzo jako u našich předešlých mikin.
            <br />
            <a href="https://www.inetprint.cz/panska-mikina-adler-malfini-essential---rtx_pm2p">Odkaz zde</a>
          </p>
          <p>
            <strong>Mikina Premium černá S,M,L,XL,2XL,3XL</strong>
            <br />
            100% bavlna s kapucí, logo na levé straně, nápis Prague Corps pravá ruka, nápis Moto Guzzi levá ruka. nápis červeně bíle ohraničený.
            <br />
            <a href="https://www.inetprint.cz/panska-mikina-adler-malfini-premium-voyage---rtx_pm2030/?showcolor=01">Odkaz zde</a>
          </p>
          <p>
            <strong>Triko – dlouhý rukáv, červené torzo, černý rukáv. S,M,L,XL,2XL,3XL</strong> 100% bavlna, logo přes celé torzo jako u našich předešlých triček.
            <br />
            <a href="https://www.inetprint.cz/panske-tricko-adler-malfini-street---rtx_pt2006/?showcolor=23">Odkaz zde</a>
          </p>
          <p>
            <strong>Triko černé – krátký rukáv S,M,L,XL,2XL,3XL,4XL,5XL</strong> 100% bavlna, logo přes celé torzo jako u našich předešlých triček.
            <br />
            <a href="https://www.inetprint.cz/panske-tricko-adler-malfini-basic---rtx_pt1019">Odkaz zde</a>
          </p>
          <p>
            <strong>Triko polo černé – S,M,L,XL,2XL,3XL,4XL,5XL&nbsp;</strong>
            <br />
            100% bavlna, logo na levé straně.
            <br />
            <a href="https://www.inetprint.cz/panska-polokosile-tee-jays-heavy-polo---rtx_pp1083">Odkaz zde</a>
          </p>
          <p>
            Počítejte cca <strong>300-500kč za kus</strong>, cena se samozřejmě bude snižovat podle toho kolik toho objednáme. Jen pro informaci, <strong>jde o velice kvalitní materiál a já osobně jsem si dnes zkoušel 3XL mikinu a se 190 cm a 125 kg mi byla větší. </strong>
          </p>
          <p>
            <strong>Délka mikiny 78,</strong>
            <br />
            <strong> šířka mikiny 75, </strong>
            <br />
            <strong>délka rukávu 69.</strong>
            <br />
          </p>
          <p>
            <strong>!!! Platba se bude vybírat předem!!!</strong>
          </p>
          <p>
            <strong>!!!Nejzazší&nbsp; termín objednání je do neděle 10.3!!!</strong>
            <br />V případě zájmu mě neváhejte kontaktovat. Tel: 727 946 670 Email: tomas.lacko93@gmail.com
          </p>
        </div>
      </div>
    </article>
  );
};

export default SimpleArticle;
