import React, { useState } from 'react';
import Albums from './Albums';
import PhotosInAlbum from './PhotosInAlbum';
import './Gallery.css';

const Gallery = () => {
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordSubmit = () => {
    setIsAuthenticated(true);
  };

  return (
    <section className="gallery-container" id="fotky">
      {!isAuthenticated ? (
        <div className="pass-container">
          <fieldset className="gallery-fieldset">
            <legend className="gallery-legend">Pro zobrazení galerie</legend>
            <input className="gallery-input" type="password" placeholder="Vlož heslo" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button className="gallery-submit" onClick={handlePasswordSubmit}>
              Vstoupit
            </button>
          </fieldset>
        </div>
      ) : (
        <>
          {!selectedAlbum ? (
            <Albums
              onAlbumClick={setSelectedAlbum}
              password={password}
              setIsAuthenticated={(value) => {
                if (!value) {
                  setIsAuthenticated(false);
                  setPassword('');
                  alert('Špatné heslo, zkuste to znovu.');
                }
              }}
              setIsLoading={(value) => {
                setIsLoading(value);
              }}
            />
          ) : (
            <PhotosInAlbum
              album={selectedAlbum}
              password={password}
              setIsLoading={(value) => {
                setIsLoading(value);
              }}
              onBackToAlbums={() => setSelectedAlbum(null)}
            />
          )}
        </>
      )}
      {isLoading ? <p className="isloading">Fotky se načítají</p> : null}
    </section>
  );
};

export default Gallery;
