import React, { useEffect, useState } from 'react';
import './Albums.css';
import axios from 'axios';

const Albums = ({ onAlbumClick, password, setIsAuthenticated, setIsLoading }) => {
  const [albums, setAlbums] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAlbums = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('https://getalbums-qozsfwer2a-uc.a.run.app', {
          headers: {
            'x-custom-password': password,
          },
        });
        setAlbums(response.data.albums);
        if (response.data.albums.length > 0) {
          setIsAuthenticated(true); // Nastaví autentizaci pouze při úspěšném načtení
        }
      } catch (err) {
        setError('Error fetching albums.');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (password) {
      fetchAlbums();
    }
  }, [password]); // `setIsAuthenticated` je nyní odstraněno z pole závislostí

  return (
    <div className="albums">
      {error && <p>{error}</p>}
      <div className="albums-container">
        {albums.map((album, index) => (
          <div key={index} className="album-item" onClick={() => onAlbumClick(album)}>
            <p className="album-name">{album}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Albums;
